.truncated {
  margin-top: 0;

  span {
    background-color: blue;
    display: -webkit-inline-box;
    overflow: hidden;
    line-clamp: 3;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }

}