html,
body,
#root {
  font-family: Ubuntu;
  height: 100%;
}

@font-face {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/Ubuntu-Regular.ttf");
  font-display: fallback;
}

@font-face {
  font-family: Ubuntu;
  font-style: italic;
  font-weight: 400;
  src: url("./assets/fonts/Ubuntu-Italic.ttf");
  font-display: fallback;
}

.pf-v5-c-toggle-item-dark .pf-m-selected {
  --pf-v5-c-toggle-group__button--BackgroundColor: var(--pf-v5-global--palette--blue-400);
}

.pf-v5-c-content {
  /* --pf-v5-c-content--small--Color: red;*/  /* changes all <small> color to red */
  --pf-v5-c-content--blockquote--BorderLeftColor: purple; /* changes all <blockquote> left border color to purple */
  --pf-v5-c-content--hr--BackgroundColor: lemonchiffon; /* changes a <hr> color to lemonchiffon */
}

.altron-color-primary {
  color: #204759;
}

/*
.pf-v5-c-notification-badge.pf-m-unread {
  --pf-v5-c-notification-badge--after--BackgroundColor: #46DADF;
}
*/

/*
.pf-v5-c-notification-badge.pf-m-unread:hover {
  --pf-v5-c-notification-badge--after--BackgroundColor: #36CACF;
}
*/

/*
.pf-v5-c-button.pf-m-primary {
  background-color: #1F4650;
  color: #FFFFFF;
}

.pf-v5-c-button.pf-m-primary:hover {
  background-color: #0F3640;
}

.pf-v5-c-button.pf-m-secondary {
  --pf-v5-c-button--after--BorderColor: #46DADF;
  color: #46DADF;
}

.pf-v5-c-button.pf-m-secondary:hover {
  --pf-v5-c-button--after--BorderColor: #46DADF;
}
*/
:where(:root) {
  --pf-v5-global--FontFamily--text: Ubuntu, "RedHatText", helvetica, arial, sans-serif;
  --pf-v5-c-button--m-primary--BackgroundColor:: #46DADF;
}

:where(:root) {
  --pf-v5-global--FontFamily--heading: Ubuntu, "RedHatText", helvetica, arial, sans-serif;
}

.pf-v5-c-masthead {
  --pf-v5-c-masthead--BackgroundColor: #28468D;
}

:where(.pf-v5-theme-dark) .pf-v5-c-masthead {
  --pf-v5-c-masthead--BackgroundColor: var(--pf-v5-global--palette--black-1000);
}

.pf-v5-c-masthead .pf-v5-c-toolbar {
  --pf-v5-c-toolbar--BackgroundColor: #28468D;
}

:where(.pf-v5-theme-dark) .pf-v5-c-masthead .pf-v5-c-toolbar {
  --pf-v5-c-toolbar--BackgroundColor: var(--pf-v5-global--palette--black-1000);
}

.pf-v5-c-page__sidebar {
  background-color: #28468D;
}

:where(.pf-v5-theme-dark) .pf-v5-c-page__sidebar {
  background-color: var(--pf-v5-c-page__sidebar--BackgroundColor);
}

.pf-v5-c-about-modal-box {
  /* background-color: #1F4650; */
  background-color: #28468D;
}

.pf-v5-c-background-image {
  background-color: #28468D;
}

.pf-v5-c-nav__link.pf-m-current, .pf-v5-c-nav__link.pf-m-current:hover, .pf-v5-c-nav__item.pf-m-current:not(.pf-m-expanded) .pf-v5-c-nav__link {
  background-color: #1F4650;
}

.pf-v5-c-nav__link:hover, .pf-v5-c-nav__link.pf-m-hover {
  background-color: #1F4650;
}

.pf-v5-c-nav {
  --pf-v5-c-nav__link--before--BorderColor: #1F4650;
  --pf-v5-c-nav__item--before--BorderColor: #1F4650;
}

.pf-v5-c-pagination {
  --pf-v5-c-pagination__nav--Display: inline-flex !important;
}

.pf-v5-c-pagination__total-items {
  display: none !important;
}

.pf-v5-c-pagination .pf-v5-c-menu-toggle {
  display: inline-flex !important;
}

