.pf-v5-c-background-image {
  --pf-v5-c-background-image--BackgroundPosition: top right !important;
/*
  --pf-v5-c-background-image--BackgroundSize--width: 60%;
  --pf-v5-c-background-image--BackgroundSize--min-width: 200px;
  --pf-v5-c-background-image--BackgroundSize--max-width: 600px;
  --pf-v5-c-background-image--BackgroundSize: clamp(
    var(--pf-v5-c-background-image--BackgroundSize--width),
    var(--pf-v5-c-background-image--BackgroundSize--min-width),
    var(--pf-v5-c-background-image--BackgroundSize--max-width);
  );
*/
  background-size: var(--pf-v5-c-background-image--BackgroundSize);
  background-size: 50vw 100vh !important;
}

.pf-v5-c-login__main-footer-links {
  padding-inline-start: 2em !important;
  padding-inline-end: 2em !important;
}
